<template>
  <!-- eslint-disable -->
  <v-container id="rfp-page" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12">
        <base-material-card color="primary" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Conference Organiser Subscribers
            </div>
            <div class="subtitle-1 font-weight-light">
              A list of Conference Organisers
            </div>
          </template>
          <v-card-text>
            <v-row
              class="mx-0"
              align="center"
              justify="center"
              justify-md="start"
            >
              <v-col cols="12" md="8" lg="5" class="px-0">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  outlined
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="px-0">
                <v-data-table
                  :headers="headers"
                  :search="search"
                  :items="items"
                  :loading="isLoading"
                >
                  <template v-slot:[`item.email`]="{ item }">
                    <v-tooltip bottom v-if="item.email">
                      <template v-slot:activator="{ on }">
                        <span class="subtitle-1 text-left" v-on="on">
                          <a :href="`mailto:${item.email}`">{{ item.email }}</a>
                        </span>
                      </template>
                      <span>
                        Send an email to
                        <b>{{ item.name }}</b> via
                        <b>
                          <i>{{ item.email }}</i>
                        </b>
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.created_at`]="{ item }">
                    <!-- <span class="subtitle-1 text-left" v-on="on"> -->
                    {{ DateFormatYYMMDD(item.created_at) }}
                    <!-- </span> -->
                  </template>
                  <template v-slot:item.delete="{ item }">
                    <v-dialog
                      v-model="item.isModal"
                      :key="item._id"
                      persistent
                      max-width="400"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon color="red">
                          <font-awesome-icon :icon="['fas', 'trash-alt']" />
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <h3 class="title text-start text-break">
                            Are you sure you want to delete '{{ item.name }}'?
                          </h3>
                        </v-card-title>
                        <v-card-text>
                          <p class="subtitle-2 text-left">
                            You will be deleting all the data associated with
                            this Conference Organiser. Are you sure?
                          </p>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="secondary"
                            text
                            @click="item.isModal = false"
                            >No, Cancel</v-btn
                          >
                          <v-btn color="error" text @click="deleteFn(item)"
                            >Yes, Delete</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                  <template v-slot:item.AddRFP="{ item }">
                    <RFPCredit @initFN="initFn" :key="item._id" :item="item" />
                  </template>
                  <template v-slot:item.status="{ item }">
                    <v-btn
                      icon
                      color="grey lighten-1"
                      @click="RouteFn(item._id, item.email)"
                    >
                      <font-awesome-icon :icon="['fas', 'chevron-right']" />
                    </v-btn>
                  </template>
                </v-data-table>

                <!-- </v-data-table> -->
              </v-col>
            </v-row>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import OrganiserApicalls from "../../../services/organiser.service";
import RFPCredit from "./Models/RFPCredit";
import DateFormatYYMMDD from "../../../mixin/DateFormatYYMMDD";

export default {
  name: "RFP",
  components: {
    RFPCredit,
  },
  data() {
    return {
      search: "",
      isLoading: false,
      deleteDialog: false,
      items: [],
    };
  },
  computed: {
    headers() {
      let adminType = JSON.parse(localStorage.getItem("admin")).admin.adminType;

      if (adminType == "master" || adminType == "superAdmin") {
        return [
          {
            sortable: true,
            text: "Organiser",
            value: "organisation",
            class: "primary--text title",
          },
          {
            sortable: true,
            text: "Org. Representative",
            value: "name",
            class: "primary--text title",
          },
          {
            sortable: true,
            text: "Email",
            value: "email",
            class: "primary--text title",
          },
          {
            sortable: true,
            text: "Joined Date",
            value: "created_at",
            class: "primary--text title",
          },
          {
            sortable: false,
            text: "Delete",
            value: "delete",
            class: "primary--text title",
          },
          {
            sortable: true,
            text: "Add RFP",
            value: "AddRFP",
            class: "primary--text title",
          },
          {
            sortable: false,
            text: "More",
            value: "status",
            class: "primary--text title",
          },
        ];
      } else {
        return [
          {
            sortable: true,
            text: "Organiser",
            value: "organisation",
            class: "primary--text title",
          },
          {
            sortable: true,
            text: "Org. Representative",
            value: "name",
            class: "primary--text title",
          },
          {
            sortable: true,
            text: "Email",
            value: "email",
            class: "primary--text title",
          },
          {
            sortable: true,
            text: "Joined Date",
            value: "created_at",
            class: "primary--text title",
          },
          {
            sortable: true,
            text: "Add RFP",
            value: "AddRFP",
            class: "primary--text title",
          },
          {
            sortable: false,
            text: "More",
            value: "status",
            class: "primary--text title",
          },
        ];
      }
    },
  },
  mounted() {
    this.isLoading = true;
    OrganiserApicalls.getAllOrg().then((res) => {
      //console.log(res.data);
      this.items = res.data.content.data.map((x) => {
        return {
          ...x,
          isModal: false,
        };
      });
      this.isLoading = false;
    });
    // .catch(err => {
    //   // console.log(err);
    // });
  },
  methods: {
    DateFormatYYMMDD,
    initFn() {
      this.isLoading = true;
      OrganiserApicalls.getAllOrg().then((res) => {
        //console.log(res.data);
        this.items = res.data.content.data.map((x) => {
          return {
            ...x,
            isModal: false,
          };
        });
        this.isLoading = false;
      });
    },
    deleteFn(val) {
      this.isLoading = true;
      let payload = {
        _id: val._id,
      };
      OrganiserApicalls.setDelete(payload).then((res) => {
        if (res.data.status == "success") {
          let payload2 = {
            snackBarText: "Conference Organizer Has been Deleted Successfully",
            snackBarColor: "secondary",
          };
          this.deleteDialog = false;
          this.$store.dispatch("notify/setSnackbarMessage", payload2);
          OrganiserApicalls.getAllOrg().then((res) => {
            this.items = res.data.content.data;
          });
        }
        this.isLoading = false;
      });
      // console.log("conf Org  ", payload);
    },
    RouteFn(id, email) {
      //update Email in Vuex Store
      this.$store.dispatch("setUserEmail", email);
      localStorage.setItem("setUserEmail", email);
      //then do routing
      this.$router.push(`/organiser-view/${id}`);
      // console.log(id);
    },
  },
};
</script>
