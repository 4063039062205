<template>
  <v-dialog v-model="vModelDialog" :key="item._id" max-width="500" scrollable>
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" icon color="grey lighten-1">
        <v-icon color="blue">mdi-comment-text-multiple</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="px-2 px-sm-6">
        <v-row class="pb-3">
          <v-col
            cols="8"
            lg="10"
            class="d-flex flex-column justify-center align-start py-0"
          >
            <h2 class="title text-start text-break">
              Update organiser's RFP Credit count
            </h2>
            <!-- {{ item.RFPCredit }} -->
          </v-col>
          <v-col
            cols="4"
            lg="2"
            class="d-flex flex-column justify-center align-end py-0"
          >
            <v-btn icon color="grey lighten-1" @click="vModelDialog = false">
              <v-icon class="dialog-close-icon">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <p class="title text-start text-break">
          Organiser Name :
          <span class="text-capitalize font-weight-bold">{{ item.name }}</span>
        </p>
        <p class="title text-start text-break">
          Existing Credit/s :
          <span class="text-capitalize font-weight-bold">{{
            item.RFPCredit
          }}</span>
        </p>
        <v-row class="mx-0" align="start" justify="space-between">
          <v-text-field
            type="number"
            ref="Credit"
            v-model="rfpCredit"
            label="RFP Credit"
            :error-messages="errorRfpCredit"
            outlined
          ></v-text-field>
        </v-row>
      </v-card-text>
      <v-card-actions class="px-6">
        <v-row
          class="
            d-flex
            flex-column-reverse flex-sm-row
            align-center
            justify-space-between
            mx-0
          "
        >
          <v-col
            cols="12"
            sm="4"
            class="d-flex flex-column align-center align-sm-start px-0"
          >
            <v-btn
              class="px-8 mx-0"
              color="secondary"
              @click="vModelDialog = false"
              tile
              outlined
              depressed
              bottom
              >Cancel</v-btn
            >
          </v-col>
          <v-col
            cols="12"
            sm="4"
            class="d-flex flex-column align-center align-sm-end px-0"
          >
            <v-btn
              class="px-8 mx-0"
              @click="submitFn"
              color="secondary"
              tile
              depressed
              bottom
              right
              >Submit</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import OrganiserApicalls from "../../../../services/organiser.service";

export default {
  name: "RFPCredit",
  props: {
    item: Object,
  },
  data() {
    return {
      vModelDialog: false,
      rfpCredit: null,
      errorRfpCredit: null,
    };
  },
  watch: {
    rfpCredit() {
      this.errorRfpCredit = null;
    },
  },
  methods: {
    async submitFn() {
      if (this.rfpCredit) {
        try {
          let payload = {
            id: this.item._id,
            credit: this.rfpCredit,
          };
          let { data } = await OrganiserApicalls.setRFPCredit(payload);
          // console.log(data);
          if (data.success) {
            this.vModelDialog = false;
            this.rfpCredit = null;
            let payload2 = {
              snackBarText: "City Topics updated successfully",
              snackBarColor: "secondary",
            };
            this.$store.dispatch("notify/setSnackbarMessage", payload2);
            this.$emit("initFN");
          }
        } catch (error) {
          let payload2 = {
            snackBarText: error,
            snackBarColor: "error",
          };
          this.$store.dispatch("notify/setSnackbarMessage", payload2);
        }
      } else {
        this.errorRfpCredit = "REP filed should not be empty";
      }
    },
  },
};
</script>
<style scoped>
.img-placeholder {
  width: 100%;
  height: auto;
  border-radius: 5px;
}
.img-cover-placeholder {
  width: 100%;
  height: auto;
  max-height: 300px;
  border-radius: 5px;
}
</style>
<style>
.v-dialog .v-card .v-card__title .v-icon.dialog-close-icon {
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 32px;
  height: 32px;
  font-size: 24px;
}
</style>